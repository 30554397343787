.date-with-data {
    > button {
        background: #8bc34a;
        color: #fff !important;
        border: none; /* Sin borde por defecto */
    }

    &.active {
        > button {
            border: 2px solid #3f51b5; /* Borde azul cuando está activa */
        }
    }
}

.date-with-cloud {
    > button {
        background: #ee8b7e;
        color: #fff !important;
        border: none; /* Sin borde por defecto */
    }

    &.active {
        > button {
            border: 2px solid #3f51b5; /* Borde azul cuando está activa */
        }
    }
}


a {
    &.disabled {
        color: var(--kt-gray-300) !important;
        cursor: wait;

        &:hover {
            color: var(--kt-gray-300) !important;
        }  
    }
}