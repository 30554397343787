.label-land {
    background: white;
    padding: 5px;
    z-index: 2;
}

.label-field {
    background: white;
    padding: 5px;
}

.label-gap {
    background: white;
    padding: 5px;
    opacity: 0.3;
    z-index: 1;

    &:hover {
        opacity:1;
        z-index: 3;
    }
}