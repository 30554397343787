.box-square-ambient {
    width: 30px;
    height: 100%;
    
    &.mbp {
        background: rgb(215,25,28);
    }

    &.bp {
        background: rgb(241,124,74);
    }

    &.pp {
        background: rgb(254,201,129);
    }

    &.ap {
        background: rgb(255,255,192);
    }

    &.map {
        background: rgb(196,230,135);
    }

    &.five {
        background: rgb(119,195,92);
    }

    &.six {
        background: rgb(26,150,65);
    }
}
