.box-square {
    width: 100%;
    height: 14px;
    margin-top: 3px;
    
    &.int1 {
        background: rgb(26, 150, 65);
    }

    &.int2 {
        background: rgb(166, 217, 106);
    }

    &.int3 {
        background: rgb(253, 174, 97);
    }

    &.int4 {
        background: rgb(215, 25, 28);
    }
}
