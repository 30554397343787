@import "./Gis.variables.scss";

.gis-container { 
    z-index: 1;
    position: absolute;
    top: calc(120px + 0.5rem);
    left: 0.5rem;
    right: 0.5rem;

    .grid-container {
        justify-content: space-between;
        >div:last-child {
            margin-left: auto;   
        }
    }
}

@media (max-width: 700px) {
    .right-gis-container {
        display: none !important;
    }

    .search-mobile {
        display: block !important;
    }

    .right-card-data { 
        box-shadow: none !important;
        border-bottom: 1px solid #ddd;
    }
}

@media (min-width: 700px) {
    .search-mobile {
        display:none !important;
    }
}

.right-gis-container { 
    position: absolute;
    top: 15px;
    right: 1rem;

    .displayable {
        z-index: 1;
    }
}

.left-gis-container { 
    z-index: 1;
    position: absolute;
    top: 60%;
    left: 1rem;
}

.div-type-selector {
    &:not(.disabled) {
        &:hover {
            background-color: var(--kt-primary-light) !important;
            cursor: pointer;

            span {
                transition: color 0.2s ease;
                color: var(--kt-text-primary) !important;
            }
        }
        /* Ensuring .gen-button inside .div-type-selector does not change on hover */
        .gen-button {
        //     background-color: var(--kt-gray-light) !important;
        //     // cursor: inherit !important;
            
        //     span {
        //         color: var(--kt-text-primary) !important;
        //     }

            &.disabled {
                background-color: var(--kt-gray-300) !important;
                span {
                    color: var(--kt-gray-800) !important;
                }
            }
        }
    }


    svg { 
        width: 24px;
        height: 24px;
    }

    &.disabled {
        background-color: var(--kt-gray-300) !important;
    }

    &.disabled:not(.active) {
        cursor: wait;
    }
    
    &.active {
        background-color: var(--kt-primary-light) !important;
        span {
            color: var(--kt-text-primary) !important;
        }
    }
}
.gen-button {
    
    background-color: var(--kt-gray-200) !important;
    &:not(.disabled) {
        &:hover {
            cursor: pointer;
            background-color: var(--kt-white) !important;
        }
        span {
            transition: color 0.2s ease;
            color: var(--kt-text-primary) !important;
        }
    }


    svg { 
        width: 24px;
        height: 24px;
    }

    &.disabled {
        background-color: var(--kt-gray-300) !important;
        cursor:not-allowed;
        span {
            transition: color 0.2s ease;
            color: var(--kt-gray-800) !important;
        }
        &:hover {
            background-color: var(--kt-gray-300) !important;
            cursor:not-allowed ;

            span {
                transition: color 0.2s ease;
                color: var(--kt-gray-800) !important;
            }
        }
    }
    
    &.active {
        background-color: var(--kt-primary-light) !important;
        span {
            color: var(--kt-text-primary) !important;
        }
    }
}

.disabled-link {
    opacity: 0.5;
    cursor: not-allowed;
}

.modal-header-with-actions{
    justify-content: flex-start;
}