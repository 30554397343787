.map-draw-control {
    height:100%;
    span {
        float: left;
    }

    .button-action {
        float: left;
        margin-right: 5px;
        position: relative;

        img {
            width: 24px;
            height: 24px;
        }

        .file-selector {
            opacity:0;
            width: 100%;
            height: 100%;
            position:absolute;
            z-index: 1;
            top: 0;
            left: 0;
            cursor: pointer;
        }
    }
}
