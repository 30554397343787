.input-base {
    border: 1px solid #ddd;
    padding: 2px;
}

.box-cls {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 0.5px solid #000000;
}

.text-title {
    font-weight: 500 !important;
}

.input-base {
    width: 100%;
}