@import "../Gis.variables.scss";

.map-loading {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: rgba(255, 255, 255, 0.4);
  }

.map-container { 
    height: 100%;
    position: fixed;
    width: 100%;
    top: $top-margin;
}

.mapboxgl-control-container {
    position: absolute;
    z-index: 1;
    top: 5px;
}

@media (max-width: 700px) {
    .map-container {
        top: 55px !important;
    }
}